.page.rankings {
	position: relative;
	padding-top: vw(90);

	.lightning-bg-icon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: vw(1227);
		height: auto;
		top: vw(-77);
		left: vw(-42);
	}

	.ctn {
		position: relative;
		--spacing: #{vw(12)};
		
		.refresh-btn {
			position: absolute;
			top: 0;
			right: vw(27);
			z-index: 2;

			.move {
				display: flex;
				align-items: center;
				transform: translate(0, -50%);
	
				color: var(--color-text-primary);
				font-family: var(--font-family-title);
				font-size: vw(30);
				font-weight: bold;
				line-height: 116%;
				letter-spacing: -0.022em;
				text-transform: uppercase;
			}

			.refresh-icon {
				width: vw(40);
				height: auto;
			}
		}

		.top {
			--spacing: #{vw(21)};
			text-align: center;
			box-shadow: vw(0) vw(8) vw(8.3) rgba(0, 0, 0, 0.3);
			margin-bottom: vw(33);
			border: vw(1) solid rgba(black, 0.2);

			.trophy-icon {
				width: vw(75);
				height: auto;
			}

			.title {
				font-family: var(--font-family-title);
				font-weight: bold;
				font-size: vw(48);
				line-height: 89%;
				text-transform: uppercase;
	
				color: var(--color-background-secondary);
				margin-top: vw(12);
				margin-bottom: vw(35);
			}
		}
	}

	.leaderboard-description {
		margin: vw(45) 0;
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(36);
		line-height: 110%;
		text-align: center;
		color: var(--color-background-secondary);
	}

	.leaderboard {
		display: grid;
		grid-template-columns: 1fr;
		gap: vw(10);

		.entry {
			display: flex;
			align-items: center;
			padding: vw(5) vw(5) vw(5) 0;
			background: rgba(black, 0.2);
			border-radius: vw(6);

			.rank {
				font-family: var(--font-family-title);
				font-weight: bold;
				text-align: center;
				font-size: vw(48);
				flex: 0 0 vw(100);
				color: var(--color-button-border);
			}

			.data {
				flex: 1 1 100%;
				display: flex;
				align-items: center;
				padding: vw(7) vw(18);

				.avatar {
					flex: 0 0 auto;
					margin-right: vw(17);
	
					.empty-avatar,
					img {
						width: vw(100);
						height: vw(100);
						padding: vw(3);
						border: vw(1) solid rgba(black, 0.2);
						border-radius: 100%;
						overflow: hidden;
					}

					.empty-avatar {
						background: rgba(black, 0.2);
					}
				}
	
				.content {
					flex: 1 1 100%;
					border-right: 1px solid rgba(black, 0.2);
					align-self: stretch;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.name {
						font-family: var(--font-family-title);
						font-weight: bold;
						font-size: vw(24);
						line-height: 103%;
						text-transform: uppercase;
					}
				}
	
				.xp {
					flex: 0 0 auto;
					width: vw(146);
					font-weight: 700;
					font-size: vw(24);
					line-height: 110%;
					text-align: right;
					letter-spacing: 0.02em;
				}
			}
		}
	}
	
	&.enter,
	&.appear {
		> .lightning-bg-icon {
			transform: translate(100%, 0);
		}

		.ctn,
		.top,
		.refresh-btn,
		.leaderboard-description,
		.entry {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			> .lightning-bg-icon {
				transform: translate(0, 0);
				transition: transform 0.9s $easeOutCubic var(--delay, 0s);
			}

			.ctn,
			.top,
			.refresh-btn,
			.leaderboard-description,
			.entry {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.ctn { --delay: 0s }
		.top { --delay: 0.1s }
		.leaderboard-description { --delay: 0.15s }
		.refresh-btn { --delay: 0.2s }

		.leaderboard {
			@for $i from 1 through 20 {
				.entry:nth-child(#{$i}) { --delay: #{0.05 * $i + 0.25}s; }
			}
		}

		>.lightning-bg-icon { --delay: 0.3s }
	}
}