%reset_button {
	padding: 0;
	background-color: transparent;
	border: 0;
}

%base_button {
	position: relative;
	display: inline-block;
	font-size: vw(24);
	text-transform: uppercase;
	color: var(--color-text-primary);
	background-color: transparent;
	border: none;
	padding: 0;
	
	font-family: 'Inter';
	font-style: normal;
	line-height: 0.83333;
	text-align: center;
	font-weight: bold;
	cursor: pointer;

	transition: color 0.6s ease, opacity 0.6s ease;

	--shadow-distance: #{vw(10)};

	.content-wrapper {
		background-color: var(--color-button-background);
		padding: vw(33) vw(80) vw(27);
		border-radius: 9999px;
		box-shadow: var(--shadow-distance) var(--shadow-distance) 0 #000000;
		transform: translate(calc(-1 * var(--shadow-distance)), calc(-1 * var(--shadow-distance)));

		transition: transform 0.1s ease, box-shadow 0.1s ease;
	}

	&.no-offset {
		.content-wrapper {
			transform: translate(0, 0);
		}
	}

	span {
		color: inherit;
	}

	&.disabled {
		opacity: 0.25;
		pointer-events: none;
	}

	&:hover {
		color: white;
		text-decoration: none;
	}

	&:active {
		.content-wrapper {
			transform: translate(0, 0);
			box-shadow: 0px 0px 0px #000000;
		}

		&.no-offset {
			.content-wrapper {
				transform: translate(var(--shadow-distance), var(--shadow-distance));
			}
		}
	}

	&.no-pointer {
		pointer-events: none;
	}

	&.accent .content-wrapper {
		background-color: var(--color-accent-primary);
	}
}

[class^=button] {
	@extend %base_button;

	&.round {
		.content-wrapper {
			font-size: 0;
			padding: 0;
			width: vw(120);
			height: vw(120);
			border-radius: 100%;

			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				width: vw(56);
				filter: drop-shadow(vw(5) vw(5) 0 rgba(#000, 0.3));
			}
		}
	}
}