.partners {
	.sections {
		margin-top: vw(94);
	}

	.section {
		margin: 0 vw(67) vw(62);

		&:not(:last-child) {
			border-bottom: vw(1) solid rgba(black, 0.4);
		}

		.logo {
			width: vw(300);
			margin-bottom: vw(41);

			svg {
				width: 100%;
				height: auto;
			}
		}

		.title {
			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(36);
			line-height: 128%;
			text-transform: uppercase;
			color: #000000;
			margin-bottom: vw(22);
		}

		.description {
			font-size: vw(24);
			line-height: 160%;
			color: #000000;
			margin-bottom: vw(22);
		}

		.contact-us {
			display: flex;
			align-items: center;
			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(24);
			line-height: 128%;
			text-transform: uppercase;
			color: #000000;
			margin-bottom: vw(36);

			.chevron-down {
				margin-left: vw(17);
				transform: translate(0, -15%) rotate(-90deg);

				path {
					stroke: #000000;
				}
			}
		}
	}
	
	&.enter,
	&.appear {
		.section {
			border-bottom-color: rgba(black, 0);
		}

		.logo,
		.title,
		.description,
		.contact-us {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		@for $i from 1 through 5 {
			.section:nth-child(#{$i}) {
				--top-delay: #{0.1 * $i}s;
			}
		}

		&-active {
			.section {
				border-bottom-color: rgba(black, 0.4);
				transition: border-color 0.6s $easeOutCubic calc(var(--top-delay) + 0.25s);

				.logo,
				.title,
				.description,
				.contact-us {
					opacity: 1;
					transform: translate(0, 0);
					transition: opacity 0.6s $easeOutCubic calc(var(--top-delay, 0s) + var(--delay, 0s)), transform 0.6s $easeOutCubic calc(var(--top-delay, 0s) + var(--delay, 0s));
				}
			}
		}

		.logo { --delay: 0s }
		.title { --delay: 0.1s }
		.description { --delay: 0.15s }
		.contact-us { --delay: 0.2s }
	}
}