.circle-progress {
	--size: #{vw(125)};
	--stroke-width: #{vw(10)};
	--center: calc(var(--size) / 2);
	--radius: calc(var(--center) - var(--stroke-width));
	--stroke-track-color: white;
	--stroke-progress-color: #462875;

	--progress: 0;

	--arc-length: calc(2 * var(--radius) * 3.141592);
	--arc-offset: calc(var(--arc-length) * ((100 - var(--progress)) / 100));

	width: var(--size);
	height: var(--size);
	transform: rotate(-90deg);
}

.circle-progress-track,
.circle-progress-indication {
	cx: var(--center);
	cy: var(--center);
	r: var(--radius);
	fill: transparent;
	stroke-width: var(--stroke-width);
}

.circle-progress-track {
	stroke: var(--stroke-track-color);
	stroke-width: calc(var(--stroke-width) - 1px);
}

.circle-progress-indication {
	stroke: var(--stroke-progress-color);
	stroke-dasharray: var(--arc-length);
	stroke-dashoffset: var(--arc-offset);

	.safari & {
		stroke-dasharray: round(var(--arc-length), 1px);
	}
}