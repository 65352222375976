.home {
	.buzzr-icon {
		position: absolute;
		width: vw(616);
		height: vw(238);
		left: calc(50% - #{vw(616)}/2 + #{vw(16)});
		top: vw(64);
	}

	.lightning-bg-icon.top {
		position: absolute;
		width: vw(1760);
		height: vw(2123);
		left: vw(-1115);
		top: vw(-650);

		--background-color: transparent;
	}

	.buzzr-qr-code {
		position: absolute;
		width: vw(351);
		height: vw(351);
		left: vw(159);
		top: calc(50% - #{vw(351)}/2 - #{vw(445)});
	}

	.title {
		position: absolute;
		width: vw(395);
		height: vw(152);
		left: vw(587);
		top: vw(471);

		font-family: var(--font-family-title);
		font-style: normal;
		font-weight: 900;
		font-size: vw(48);
		line-height: 99%;

		color: var(--color-background-secondary);
	}

	.text {
		position: absolute;
		width: 100%;
		text-align: center;
		left: 0;
		top: vw(738);

		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(42);
		line-height: 116%;
		text-align: center;
		letter-spacing: -0.011em;

		color: var(--color-background-secondary);
	}

	.partners {
		/* image 2 */
		position: absolute;
		left: vw(96);
		width: calc(100% - #{vw(96 * 2)});
		top: vw(953);

		display: flex;
		justify-content: space-between;
		align-items: center;

		svg {
			width: auto;
			height: vw(54);

			path {
				fill: var(--color-background-secondary);
			}
		}

		.fremantle-logo {
			height: vw(45);
		}
	}

	.lightning-bg-icon.bottom {
		position: absolute;
		width: vw(1760);
		height: vw(2123);
		left: vw(258);
		top: vw(1001);

		--foreground-color: #{var(--color-background-secondary)};
	}
}
