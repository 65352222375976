label.select {
	position: relative;

	font-family: var(--font-family-title);
	font-style: normal;
	font-weight: bold;
	font-size: vw(20);
	line-height: 116%;
	/* or 23px */
	letter-spacing: -0.022em;
	text-transform: uppercase;
	display: inline-flex;
	align-items: center;
	height: vw(90);
	min-width: vw(360);

	color: var(--color-text-primary);

	.text {
		padding-right: vw(30);
		border-right: vw(1) solid rgba(#000, 0.3);
		flex: 1 1 100%;
	}

	.arrow {
		padding: 0 vw(13) 0 vw(30);
		flex: 0 0 auto;
	}

	.chevron-down {
		width: vw(24);
	}

	select {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}