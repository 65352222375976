.terms-conditions {
	color: var(--color-text-secondary);

	.header + .section {
		margin-top: vw(94);
	}

	.section {
		margin: 0 vw(67) vw(62);
	}

	.title {
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(36);
		line-height: 128%;
		text-transform: uppercase;
		margin-bottom: vw(29);
	}

	p, ul {
		font-size: vw(24);
	}

	h1, h2, h3 {
		font-size: vw(32);
	}&.enter,
	&.appear {
		.title,
		.text {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			.title,
			.text {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0.2s), transform 0.6s $easeOutCubic var(--delay, 0.2s);
			}
		}

		.title { --delay: 0.1s }
		.text { --delay: 0.2s }
	}
}