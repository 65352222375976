.box {
	--spacing: #{vw(18)};
	--border-opacity: 0.3;
	background: var(--color-background-primary);
	border: vw(3) solid rgba(#000, var(--border-opacity));
	outline: vw(5) solid rgba(#000, 0.2);
	border-radius: vw(6);

	padding: var(--spacing) var(--spacing);

	&,
	&.primary {
		background: var(--color-background-primary);
	}

	&.secondary {
		background: var(--color-background-secondary);
		color: var(--color-text-secondary);
	}

	&.accent {
		background: var(--color-accent-primary);
		color: var(--color-text-primary);

		&.secondary {
			background: var(--color-accent-secondary);
			color: var(--color-text-primary);
		}
	}

	&.good {
		background: #47A36C;
	}

	&.wrong {
		background: #B4203A;
	}

	&.sub-box {
		outline: none;
	}
}