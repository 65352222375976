.stat-bar {
	--foreground-color: var(--color-accent-secondary);
	--background-color: #{rgba(#1F2226, 0.5)};
	--text-color: var(--color-text);

	position: relative;
	overflow: hidden;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--background-color);

	font-size: vw(20);
	font-weight: bold;
	line-height: 0.7;
	z-index: 1;

	.stat-bar-inner {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		z-index: 0;
		transition: width 0.6s ease;
		--spacing: #{vw(1.5)};
		min-width: 3.5ch;

		.inside {
			width: 100%;
			height: 100%;
			--spacing: 0;
			opacity: 0.5;
		}
	}

	.stat-bar-current-level,
	.stat-bar-next-level {
		position: relative;
		z-index: 2;
	}
}
