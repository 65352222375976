.countdown {
	display: grid;
	align-items: center;
	justify-items: center;

	.num,
	.circle-progress {
		grid-row: 1;
		grid-column: 1;
		--stroke-track-color: #{rgba(black, 0.3)};
		--stroke-progress-color: var(--color-accent-primary);
	}

	.num {
		width: vw(80);
		height: vw(80);
		background: white;
		color: black;
		font-size: vw(40);
		line-height: 1;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		padding-bottom: 0.05em;
	}
}