.factoid-toast {
	.wrapper {
		.factoid {
			display: flex;

			.left {
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: 14px;
					right: 0;
					width: 2px;
					height: calc(100% - 28px);
					background: rgba(#000, 0.2);
				}
			}

			.right {
				padding: 0 25px 15px;
			}
		}

		.sponsor {
			display: flex;
			margin-bottom: 25px;
			position: relative;
			left: -11px;

			.sponsor-text {
				flex: 0 0 auto;
				padding-top: 10px;
				font-size: 20px;
				line-height: 1.34;
				font-family: 'Inter', sans-serif;

				.name {
					font-weight: bold;
					font-style: italic;
				}
			}
		}

		.image {
			position: relative;
			bottom: -8px;
			width: 232px;
			height: 100%;
			clip-path: polygon(0 -200%, 100% -200%, 100% 100%, 0 100%);

			img {
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 155px;
				height: auto;
				transform: translate(-50%, 0);
			}
		}

		.icon-ctn {
			height: 0;

			svg {
				position: relative;
				transform: translate(0, -50%);
			}
		}

		.text {
			font-size: 32px;
			line-height: 1.2;
			letter-spacing: 0;
			font-weight: bold;
		}
	}
}
