.ftue {
	position: relative;
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);

	.bg {
		position: absolute;
		top: vw(-120);
		left: 50%;
		width: calc(1250 / 720 * 100%);
		height: auto;
		transform: translate(-50%, -19%);

		svg {
			position: relative;
			width: 100%;
			height: auto;
		}

		.content {
			position: absolute;
			top: 35%;
			left: 50%;
			width: 44%;
			transform: translate(-50%, 0);
		}

		svg.buzzr-icon {
			width: 100%;
		}

		svg.lightning-bg-icon {
			transform: translate(2.9%, 0);
		}

		.text {
			margin-top: vw(60);
			text-transform: uppercase;
			font-weight: bold;
			font-size: vw(28);
			line-height: 110%;
			text-align: center;
			font-family: var(--font-family-title);
		}
	}

	.user-profile-scan {
		position: absolute;
		top: vw(757);
		left: 50%;
		width: 80%;
		transform: translate(-50%, 0);

		.text {
			font-family: var(--font-family-body);
			font-size: vw(24);
			margin-bottom: vw(14);
			font-weight: bold;
			color: var(--color-text-secondary);
		}

		.qr-scan {
			display: flex;
			align-items: center;

			color: var(--color-text-primary);
			font-family: var(--font-family-title);
			font-size: vw(30);
			font-weight: bold;
			line-height: 116%;
			letter-spacing: -0.022em;
			text-transform: uppercase;

			.qr-code {
				padding-right: var(--spacing);
				margin-right: var(--spacing);
				border-right: 1px solid rgba(#000, 0.3);
			}

			.scan-icon {
				width: vw(108);
				height: auto;
			}
		}
	}

	.partners {
		margin-top: vw(150);
		margin-bottom: vw(50);
		display: flex;
		justify-content: space-around;
		align-items: center;

		svg {
			width: auto;
			height: vw(32);
		}

		.fremantle-logo {
			height: vw(27);
		}
	}

	&.enter,
	&.appear {
		.content .buzzr-icon,
		.content .text,
		.user-profile-scan .text,
		.user-profile-scan .qr-scan,
		.partners > :nth-child(n) {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			.content .buzzr-icon,
			.content .text,
			.user-profile-scan .text,
			.user-profile-scan .qr-scan,
			.partners > :nth-child(n) {
				opacity: 1;
				transform: translate(0, 0);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s), opacity 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.content .buzzr-icon { --delay: 0s }
		.content .text { --delay: 0.1s }
		.user-profile-scan .text { --delay: 0.2s }
		.user-profile-scan .qr-scan { --delay: 0.3s }

		.partners {
			@for $i from 1 through 4 {
				> :nth-child(#{$i}) { --delay: #{0.05 * $i + 0.4}s; }
			}
		}
	}
}

.ftue-profile-done,
.ftue-profile-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--color-overlay-background);
		z-index: -1;
		backdrop-filter: blur(5px);
	}
}

.ftue-profile-loading {
	svg {
		width: vw(140);
		height: auto;
	}

	&.enter,
	&.appear {
		opacity: 0;

		&-active {
			opacity: 1;
			transition: opacity 0.6s $easeOutCubic;
		}
	}
}

.ftue-profile-done {
	text-align: center;
	font-family: var(--font-family-title);
	font-weight: bold;

	.button.check {
		pointer-events: none;
		margin-bottom: vw(87);

		.content-wrapper {
			width: vw(292);
			height: vw(292);

			svg {
				width: vw(156);
			}
		}
	}

	.title {
		font-size: vw(72);
		line-height: 100%;
		letter-spacing: -0.011em;
		text-transform: uppercase;
		margin-bottom: vw(25);
	}

	.text {
		font-size: vw(32);
		line-height: 110%;
		padding: 0 vw(114);
		margin-bottom: vw(138);
	}

	&.enter,
	&.appear {
		.check,
		.title,
		.text,
		.start {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			.check,
			.title,
			.text,
			.start {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.check { --delay: 0.3s }
		.title { --delay: 0.4s }
		.text { --delay: 0.5s }
		.start { --delay: 0.6s }
	}
}