.sub-page {
	position: relative;
	padding-top: vw(110);
	z-index: 1;
	height: calc(var(--vh, 1vh) * 100 - var(--menu-height));
	overflow-x: hidden;
	overflow-y: auto;
	
	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: vw(110);
		background: var(--color-background-primary);

		display: flex;
		align-items: center;
		padding: 0 vw(35);

		.back {
			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(36);
			line-height: 103%;
			text-transform: uppercase;
			color: #FFFFFF;

			.chevron-down {
				position: relative;
				transform: translate(0, -15%) rotate(90deg);
				margin-right: vw(18);
			}
		}
	}
	
	&.enter,
	&.appear {
		.header {
			transform: translate(0, -100%);
		}

		&-active {
			.header {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}
	}
}