.lights {
	display: flex;
	justify-content: space-between;

	.light {
		width: vw(17);
		height: vw(17);
		border-radius: 100%;

		background: #FFFFFF;
		box-shadow: 0px 0px vw(8) rgba(255, 255, 255, 0.15), vw(2) vw(2) vw(3) rgba(0, 0, 0, 0.3);

		transition: background-color 0.3s ease, box-shadow 0.3s ease;

		&.active {
			background: #FEF590;
			box-shadow: 0px 0px vw(8) rgba(255, 255, 255, 0.8), vw(2) vw(2) vw(3) rgba(0, 0, 0, 0.3);
		}
	}
}