.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	z-index: 2;
	opacity: 0;

	background: var(--color-background-primary);
	-webkit-backdrop-filter: blur(10px);
	-moz-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	pointer-events: none;

	&.enter {
		opacity: 0;
		transition: opacity 0.3s $easeOutCubic;

		&-active,
		&-done {
			opacity: 1;
			pointer-events: all;
		}
	}
}