
.xp-bar {
	.current-level,
	.next-level {
		font-weight: 700;
		font-size: vw(20);
	}
}

.xp-text {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-top: vw(14);

	font-size: vw(20);
	color: var(--color-background-secondary);
	letter-spacing: 0;
	font-weight: bold;
	margin-bottom: vw(-11);

	strong {
		color: var(--color-text-primary);
	}
}