@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-ExtraLight.woff2') format('woff2'),
        url('../../fonts/Mont-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-Heavy.woff2') format('woff2'),
        url('../../fonts/Mont-Heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-Black.woff2') format('woff2'),
        url('../../fonts/Mont-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-SemiBold.woff2') format('woff2'),
        url('../../fonts/Mont-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-Bold.woff2') format('woff2'),
        url('../../fonts/Mont-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-Thin.woff2') format('woff2'),
        url('../../fonts/Mont-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont';
    src: url('../../fonts/Mont-Regular.woff2') format('woff2'),
        url('../../fonts/Mont-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Book';
    src: url('../../fonts/Mont-Book.woff2') format('woff2'),
        url('../../fonts/Mont-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Hairline';
    src: url('../../fonts/Mont-Hairline.woff2') format('woff2'),
        url('../../fonts/Mont-Hairline.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');