.poppable {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 3;

	.bottom {
		position: absolute;
		bottom: 0;
		left: 0;
		height: vw(105);
		width: vw(600);
		max-width: vw(232);
		background: var(--color-accent-primary);
		clip-path: polygon(0 0, 100% 0, calc(100% - 65px) 100%, 0 100%);

		&::after {
			content: '';
			position: absolute;
			left: vw(23);
			bottom: vw(18);
			width: 100%;
			height: 100%;
			background: rgba(#000, 0.4);
			border-radius: vw(6);
		}
	}

	.wrapper {
		position: relative;
		margin-left: vw(45);
		margin-bottom: vw(45);
		padding: vw(6);
		background: var(--color-background-primary);
		border-radius: vw(6);
		z-index: 1;

		.border {
			border: 1px solid rgba(#000, 0.2);
			border-radius: vw(6);
			padding: vw(8);

			display: flex;
		}
	}

	&.enter,
	&.appear {
		transform: translate(0, 100%);

		&-active {
			transform: translate(0, 0);
			transition: transform 0.3s $easeOutCubic;
		}
	}

	&.exit {
		transform: translate(0, 0);

		&-active {
			transform: translate(0, 100%);
			transition: transform 0.3s $easeInCubic;
		}
	}
}