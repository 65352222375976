
.install-home-screen-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-overlay-background);
	backdrop-filter: blur(5px);
	z-index: 5;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: vw(80) vw(45) vw(45) vw(45);

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	.overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 200vw;
		height: 200vh;
		z-index: -2;
		transform: translate(-50%, -50%);
	}

	img {
		display: block;
		width: vw(128);
		border-radius: vw(20);
		filter: drop-shadow(0px vw(4) vw(4) rgba(0, 0, 0, 0.25));
		margin-bottom: vw(57);
	}

	.title {
		font-family: var(--font-family-title);
		font-weight: bold;

		font-size: vw(48);
		line-height: 100%;
		text-align: center;
		letter-spacing: -0.011em;
		text-transform: uppercase;
		margin-bottom: vw(50);
	}

	.text {
		font-size: vw(24);
		line-height: 110%;
		letter-spacing: -0.011em;
		margin-bottom: vw(30);

		p, li {
			margin-bottom: 1em;
		}

		li:last-child {
			margin-bottom: 0;
		}
	}

	&.enter,
	&.appear {

		img,
		.title,
		.text,
		.btn-ctn {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			img,
			.title,
			.text,
			.btn-ctn {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		--delay: 0s;
		img { --delay: 0.3s }
		.title { --delay: 0.4s }
		.text { --delay: 0.5s }
		.btn-ctn { --delay: 0.6s }
	}
}