@import '../../../css/website/utils/utils';

.realtime-visualizer {
	font-size: var(--visualizer-font-size, #{vw(32)});
	position: fixed;
	bottom: vw(20);
	left: vw(20);
	width: vw(1040);
	color: var(--visualizer-text-color, #fff);
	background: var(--visualizer-background-color, #351A60);
	height: vw(830);
	display: flex;
	flex-direction: column;
	box-shadow:
		0px 0px 2.7px rgba(0, 0, 0, 0.022),
		0px 0px 6.9px rgba(0, 0, 0, 0.031),
		0px 0px 14.2px rgba(0, 0, 0, 0.039),
		0px 0px 29.2px rgba(0, 0, 0, 0.048),
		0px 0px 80px rgba(0, 0, 0, 0.07);

	--visualizer-height: #{vw(607)};
	--visualizer-time-width: #{vw(285)};
	--visualizer-dot-size: 0.5em;
	--visualizer-dot-spacing: 1em;

	.top {
		display: flex;
		justify-content: space-between;
		background: var(--color-button-border);
		display: flex;
		align-items: center;
		justify-content: center;
		height: vw(150);
		flex: 0 0 auto;
	}

	.xr-logo {
		width: vw(266);
		height: vw(94);
	}

	.footer {
		height: vw(73);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: auto;
		flex: 0 0 auto;

		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(24);
		line-height: 89%;
		text-align: center;
		text-transform: uppercase;

		color: var(--color-background-secondary);
		background: var(--color-button-border);
	}

	.messages {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 10px;
		overflow-x: hidden;
		overflow-y: scroll;
		height: var(--visualizer-height);
		padding: vw(20) 0;
		margin: 0;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: rgba(#000, 0.025);
			border-radius: 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(#000, 0.05);
			border-radius: 0;
		}
	}

	.event {
		position: relative;
		display: flex;
		padding-bottom: 0.8em;
		
		&:last-child {
			padding-bottom: 0em;
			
			&::before {
				height: 100%;
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: var(--visualizer-dot-size);
			left: calc(var(--visualizer-time-width) - var(--visualizer-dot-spacing) + (var(--visualizer-dot-size) / 2));
			width: 1px;
			height: calc(100% + 0.8em);
			background: var(--color-button-border);
			transform: translate(-50%, 0);
		}
		
		&:after {
			content: '';
			position: absolute;
			top: 0.5em;
			left: calc(var(--visualizer-time-width) - var(--visualizer-dot-spacing));
			width: var(--visualizer-dot-size);
			height: var(--visualizer-dot-size);
			border-radius: 100%;
			background-color: var(--visualizer-accent-color, #FEF590);
			box-shadow: vw(0) vw(0) vw(10) rgba(255, 255, 255, 0.8), vw(2) vw(2) vw(3) rgba(0, 0, 0, 0.3);
		}

		.time {
			width: var(--visualizer-time-width);
			align-self: stretch;
			font-size: vw(32);
			position: relative;
			top: 0.3em;
			text-transform: lowercase;
			text-align: right;
			line-height: 1;
			padding-right: vw(55);
		}

		.content {
			padding-left: 0.3em;
			padding-right: vw(20);
			flex: 0 0 auto;
			width: calc(100% - var(--visualizer-time-width));
			display: flex;
			align-items: center;

			.avatar {
				margin: 0;
				--avatar-width: #{vw(100)};
				margin-right: vw(30);
				align-self: flex-start;
			}
		}

		.name {
			font-size: vw(32);
			font-weight: 600;
			line-height: 1.3;
			flex: 0 0 auto;
			margin-bottom: 0.2em;
			word-break: break-all;
		}

		.additional-infos {
			font-size: vw(24);
			font-style: italic;
			line-height: 1.3;
			display: flex;

			.item-placeholder {
				width: (4em / 3);
				height: (4em / 3);
				font-size: 3em;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			img {
				width: 4em;
				height: 4em;
				border-radius: 1em;
				object-fit: cover;
			}
		}
	}

	.no-events {
		text-align: center;
		margin-top: auto;
	}
}