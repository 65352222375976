.avatar {
	--avatar-width: #{vw(188)};
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	align-items: center;
	justify-items: center;

	.circle-progress {
		grid-row: 1;
		grid-column: 1;

		--size: #{vw(270)};
		--stroke-width: #{vw(20)};

		--stroke-track-color: rgba(0, 0, 0, 0.1);
		--stroke-progress-color: var(--color-accent-secondary);
	}

	.image-ctn {
		width: var(--avatar-width);
		height: var(--avatar-width);
		overflow: hidden;
		border-radius: 100%;

		grid-row: 1;
		grid-column: 1;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.spinner {
		margin: 20%;

		grid-row: 1;
		grid-column: 1;
	}
}