.lang {
	color: #000000;

	.header + .section {
		margin-top: vw(104);
	}

	.section {
		margin: 0 vw(67) vw(62);
	}

	.cols {
		display: flex;
	}

	.title {
		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(24);
		line-height: 89%;
		text-transform: uppercase;
		margin-bottom: vw(41);
	}

	.lang-input {
		width: 100%;

		.inside {
			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: vw(30);
			border-bottom: vw(1) solid rgba(black, 0.4);
		}

		&:last-child {
			.inside {
				margin-bottom: 0;
			}
		}

		input {
			display: none;
		}

		&.disabled .inside {
			opacity: 0.3;
			pointer-events: none;
		}

		font-family: var(--font-family-title);
		font-weight: bold;
		font-size: vw(36);
		line-height: 103%;
		text-transform: uppercase;

		.checkmark-icon {
			position: absolute;
			top: 50%;
			left: vw(7);
			--color: var(--color-text-secondary);
			width: vw(40);
			opacity: 0;
			transform: translate(0, -50%) scale(0.5);

			transition: opacity 0.3s ease, transform 0.3s ease;
		}

		span {
			position: relative;
			display: inline-block;
			padding: vw(12) 0;
			transform: translate(0, 0);
			transition: transform 0.6s ease;
		}

		input:checked {
			+ .checkmark-icon {
				opacity: 1;
				transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
				transform: translate(0, -50%) scale(1);
			}

			~ span {
				transform: translate(vw(73), 0);
			}
		}
	}
	
	&.enter,
	&.appear {
		.title,
		.lang-input {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		@for $i from 1 through 2 {
			.col:nth-child(#{$i}) {
				--top-delay: #{0.1 * $i}s;
			}
		}

		&-active {
			.title,
			.lang-input {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic calc(var(--top-delay, 0s) + var(--delay, 0s)), transform 0.6s $easeOutCubic calc(var(--top-delay, 0s) + var(--delay, 0s));
			}
		}

		.title { --delay: 0s }

		@for $i from 1 through 5 {
			.lang-input:nth-child(#{$i}) {
				--delay: #{0.05 * $i}s;
			}
		}
	}
}