.menu {
	position: absolute;
	bottom: 0;
	left: 0;

	&:not(.tutorial) {
		z-index: 4;
	}

	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: var(--menu-height);

	.bar {
		width: 100%;
		height: vw(10);
		background: white;
		border-top-left-radius: vw(6);
		border-top-right-radius: vw(6);
	}

	.items {
		display: flex;
		background-color: var(--color-background-primary);

		.spacer {
			flex: 1;
		}

		.menu-item {
			position: relative;
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: var(--color-background-secondary);
			font-weight: bold;
			font-size: vw(15);
			text-transform: uppercase;
			line-height: 0.7;
			padding: vw(32) 0 vw(24);

			transition: opacity 0.3s ease;

			&.disabled {
				pointer-events: none;
				opacity: 0.5;
			}

			@media (display-mode: fullscreen) {
				padding: vw(32) 0 vw(44);
			}

			&:not(:first-child) {
				border-left: 1px solid rgba(#000, 0.2);
			}

			&:nth-child(2) {
				border-right: 1px solid rgba(#000, 0.2);
			}

			text-decoration: none;

			.icon {
				width: vw(40);
				height: vw(40);
				margin-bottom: vw(13);

				svg {
					width: 100%;
					height: 100%;
					object-fit: contain;

					--icon-color: var(--color-background-secondary);
				}
			}

			&.active {
				color: white;
				background-color: var(--color-accent-primary);

				.icon {
					svg {
						--icon-color: white;
					}
				}
			}
		}

		.spacer {
			position: relative;
			--color-background: transparent;
			--color-foreground: white;
			display: flex;
			justify-content: center;
			padding: 0 vw(30);

			[class^=button] {
				transform: translate(0, vw(-18));
				filter: drop-shadow(0 0 vw(5) rgba(#000, 0.2));

				&.active .content-wrapper {
					background-color: var(--color-accent-primary);
				}
			}
		}
	}

	.arrow-icon {
		position: absolute;
		bottom: calc(100% - vw(20));
		left: 50%;
		width: vw(60);
		height: auto;
		transform: translate(-50%, 0);
		pointer-events: none;
		opacity: 0;
	}

	.overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100vw;
		height: calc(var(--vh, 1vh) * 100);
		background: rgba(#130529, 0.8);
		backdrop-filter: blur(5px);
		z-index: -2;

		opacity: 0;
		pointer-events: none;
	}

	.quiz-notification {
		position: absolute;
		bottom: 0;
		left: calc(50% - vw(251));
		width: vw(502);
		z-index: -1;

		transform: translate(0, 100%);
		pointer-events: none;

		.content {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-family: var(--font-family-title);
			font-weight: bold;

			.title {
				font-size: vw(36);
				line-height: 100%;
				text-align: center;
				letter-spacing: -0.011em;
				text-transform: uppercase;
				position: relative;
				left: vw(-10);
			}

			.text {
				font-size: vw(24);
				line-height: 110%;
				/* or 26px */
				text-align: center;
				letter-spacing: -0.011em;
				position: relative;
				left: vw(-10);
			}
		}
	}

	.overlay {
		&.appear,
		&.enter {
			opacity: 0;

			&-active {
				transition: opacity 0.3s $easeOutCubic;
			}

			&-active,
			&-done {
				opacity: 1;
				pointer-events: all;
			}
		}

		&.exit {
			opacity: 1;

			&-active,
			&-done {
				transition: opacity 0.3s $easeInCubic 0.2s;
				opacity: 0;
			}
		}
	}

	.quiz-notification {
		&.appear,
		&.enter {
			transform: translate(0, 100%);

			&-active {
				transition: transform 0.6s $easeOutExpo 0.1s;
			}

			&-active,
			&-done {
				transform: translate(0, 0);
				pointer-events: all;
			}
		}

		&.exit {
			transform: translate(0, 0);

			&-active,
			&-done {
				transition: transform 0.3s $easeInCubic;
				transform: translate(0, 100%);
			}
		}
	}

	&.tutorial {
		pointer-events: none;

		.menu-item.tutorial-active {
			pointer-events: all;
			z-index: 99;

			.arrow-icon {
				opacity: 1;
				transition: opacity 0.3s ease 2s;
			}
		}

		.spacer.tutorial-active {
			pointer-events: all;
			z-index: 99;

			.arrow-icon {
				opacity: 1;
				bottom: calc(100% + vw(10));
				z-index: 2;
				transition: opacity 0.3s ease 2s;
			}
		}
	}
}