@at-root {
	:root {
		//General variables
		--color-text-primary: #fff;
		--color-text-secondary: #000;
		--color-background-primary: #573292;
		--color-background-secondary: #FFEBC6;
		--color-background-primary-dark: #2B0864;
		--color-accent-primary: #FF4E02;
		--color-accent-secondary: #7ABBCD;
		--font-family-title: 'Mont', sans-serif;
		--font-family-body: 'Inter', sans-serif;

		--color-overlay-background: #{rgba(#2B0864, 0.9)};
	
		--color-button-text: var(--color-text-primary);
		--color-button-border: #8F61DD;
		--color-button-background: #8F61DD;

		--menu-height: #{vw(130)};

		@media (display-mode: fullscreen) {
			--menu-height: #{vw(150)};
		}
	
		--container-size: 100%;
		--container-gutter: 0px;
	
		@include media-breakpoint-down(sm) {
			--container-gutter: 0px;
		}
	}
}

%inverted_colors {
	--color-text-primary: #573292;
	--color-background-primary: #FFEBC6;
}
