.profile {
	z-index: 1;

	> .lightning-bg-icon {
		position: absolute;
		top: vw(-782);
		left: vw(-742);
		width: vw(1227);
		height: auto;
		z-index: -1;
	}

	.top {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: var(--color-text-secondary);
		text-transform: uppercase;
		margin-bottom: vw(29);

		.display-name {
			margin-top: vw(14);
			font-size: vw(48);
			font-weight: bold;
			line-height: 0.89;
			letter-spacing: 0;
		}

		.company {
			font-size: vw(20);
			line-height: 1.1;
			letter-spacing: 0;
			margin-top: 0.4em;
		}
	}

	.tab-btns {
		display: flex;
		gap: vw(22);

		.tab-btn {
			flex: 1 1 100%;
			--spacing: #{vw(10)};
			text-align: center;
			text-transform: uppercase;
			font-size: vw(18);
		}
	}

	&.enter,
	&.appear {
		> .lightning-bg-icon {
			transform: translate(-50%, -50%);
		}

		.top .avatar,
		.top .display-name,
		.top .company,
		.tab-btns .tab-btn {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			> .lightning-bg-icon {
				transform: translate(0, 0);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.top .avatar,
			.top .display-name,
			.top .company,
			.tab-btns .tab-btn {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		>.lightning-bg-icon { --delay: 0s }
		.top .avatar { --delay: 0.1s }
		.top .display-name { --delay: 0.2s }
		.top .company { --delay: 0.3s }

		.tab-btns {
			.tab-btn:nth-child(1) { --delay: 0.4s }
			.tab-btn:nth-child(2) { --delay: 0.45s }
			.tab-btn:nth-child(3) { --delay: 0.5s }
		}
	}

	&.tutorial {
		&:after {
			content: '';
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--color-overlay-background);
			z-index: 3;
		}

		.avatar {
			z-index: 10;
		}
	}
}

.tab.progress {
	margin-top: vw(73);
	z-index: 1;

	.title {
		font-size: vw(24);
		font-weight: bold;
		text-transform: uppercase;
		color: var(--color-text-secondary);
		line-height: 1.1;
		margin-bottom: vw(22);
	}

	.progression {
		position: relative;

		.lightning-bg-icon {
			position: absolute;
			left: vw(353);
			top: vw(-283);
			width: vw(552);
			height: auto;
			z-index: -1;
		}

		.lights {
			padding: vw(4) vw(13) vw(13);
		}

		.xp-bar-container {
			--spacing: #{vw(25)};
			--border-opacity: 0.1;
		}

		.progression-box {
			--spacing: #{vw(14)};
		}

		.objective {
			display: flex;
			align-items: center;
			padding: vw(32) vw(26) vw(12);

			font-family: var(--font-family-title);
			font-weight: bold;
			font-size: vw(30);
			line-height: 110%;

			color: rgba(255, 255, 255, 0.7);

			.checkbox {
				--spacing: 0;
				width: vw(56);
				height: vw(56);
				flex: 0 0 auto;
				border: none;
				margin-right: vw(21);
				display: flex;
				align-items: center;
				justify-content: center;
	
				background-color: rgba(black, 0.3);
	
				.lightning-icon {
					width: vw(26);
					--color-foreground: white;
					--color-background: transparent;
					opacity: 0;
					transition: opacity 0.3s ease;
				}
			}
	
			&.achieved {
				.checkbox {
					background-color: rgba(white, 0.3);
	
					.lightning-icon {
						opacity: 1;
					}
				}
			}
		}

		.chances {
			position: absolute;
			top: vw(-80);
			right: 0;
			display: flex;
			align-items: center;
			gap: vw(18);

			font-size: vw(48);
			font-weight: bold;
			z-index: 2;

			.button {
				pointer-events: none;

				.content-wrapper {
					width: vw(88);
					height: vw(88);
					background: var(--color-accent-secondary);
					box-shadow: vw(5) vw(5) 0 black;
					transform: none;
	
					svg {
						width: vw(35);
						filter: none;
					}
				}
			}
		}

		.stats {
			display: flex;
			gap: vw(10);
			justify-content: space-around;
			margin-top: vw(24);
			width: vw(585);

			.stat {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: vw(4);
				text-align: center;
				color: var(--color-text-secondary);

				.value {
					font-size: vw(64);
					font-weight: bold;
					line-height: 1;
				}

				.label {
					font-size: vw(18);
					font-weight: bold;
					line-height: 1.1;
					text-transform: uppercase;

					.de & {
						font-size: vw(14);
					}
				}
			}
		}
	}

	.tutorial & {
		.progression {
			z-index: 10;

			.objective {
				display: none;
			}
		}

		.tutorial-text {
			position: absolute;
			top: vw(181);
			left: vw(89);

			.arrow {
				width: vw(47);
				height: vw(129);
			}

			.text {
				position: absolute;
				top: vw(109);
				left: vw(71);
				width: vw(440);
				font-family: var(--font-family-title);
				font-weight: bold;
				font-size: vw(32);
				line-height: 110%;
				letter-spacing: -0.011em;
				transform: translate(0, -50%);
			}
		}
	}

	&.enter,
	&.appear {
		.lightning-bg-icon {
			transform: translate(100%, 0);
		}

		.progression-box,
		.title,
		.chances,
		.stat,
		.tutorial-text {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			.lightning-bg-icon {
				transform: translate(0, 0);
				transition: transform 0.6s $easeOutCubic var(--delay, 0s);
			}

			.progression-box,
			.title,
			.chances,
			.stat,
			.tutorial-text {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		.progression-box { --delay: 0s }
		.title { --delay: 0.05s }
		.chances { --delay: 0.1s }
		.lightning-bg-icon { --delay: 0.25s }
		.tutorial-text { --delay: 0.3s }

		.stats {
			.stat:nth-child(1) { --delay: 0.3s }
			.stat:nth-child(2) { --delay: 0.35s }
			.stat:nth-child(3) { --delay: 0.4s }
			.stat:nth-child(4) { --delay: 0.45s }
		}
	}
}

.tab.badges {
	margin-top: vw(33);

	> .box {
		--border-opacity: 0.1;
		padding: vw(23) vw(38);
	}

	.badges {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: vw(28);
	}

	.badge {
		position: relative;

		.move {
			display: flex;
			align-items: center;
			justify-content: center;
			--shadow-distance: 0;
	
			width: vw(184);
			height: vw(184);
			border-radius: 100%;
			background-color: var(--color-button-border);
			box-shadow: var(--shadow-distance) var(--shadow-distance) 0 #000000;
			transform: translate(calc(-1 * var(--shadow-distance)), calc(-1 * var(--shadow-distance)));
	
			transition: transform 0.3s ease, box-shadow 0.3s ease;
	
			user-select: none;
		}

		img {
			width: vw(160);
			height: vw(160);
			border-radius: 100%;
			-webkit-user-drag: none;
		}

		&.owned {
			.move {
				--shadow-distance: #{vw(10)};
				
				&:active {
					--shadow-distance: 0;
				}
			}
		}

		&:not(.owned) {
			.move {
				mix-blend-mode: luminosity;
				opacity: 0.4;
			}
		}

		.button {
			position: absolute;
			top: 0;
			right: 0;

			.content-wrapper {
				width: vw(60);
				height: vw(60);
				background-color: var(--color-accent-primary);
				--shadow-distance: #{vw(5)};

				svg {
					width: vw(34);
					filter: none;
				}
			}
		}

		.lock-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: vw(26);
		}
	}

	&.enter,
	&.appear {
		> .box,
		.badge {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			> .box,
			.badge {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		> .box { --delay: 0s }

		.badges {
			@for $i from 1 through 20 {
				.badge:nth-child(#{$i}) { --delay: #{0.05 * $i + 0.2}s; }
			}
		}
	}
}

.tab.statistics {
	margin-top: vw(33);

	> .box {
		--border-opacity: 0.1;
		--spacing: #{vw(27)};
	}

	.stats-ctn {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: vw(20);
	}

	.stat {
		display: flex;
		padding-left: 0;
		padding-right: vw(10);
		align-items: center;

		--spacing: #{vw(15)};

		.qty {
			font-size: vw(64);
			font-weight: bold;
			text-align: center;
			flex: 0 0 vw(110);
			border-right: 1px solid rgba(#000, 0.3);
			margin-right: vw(20);
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: vw(64);

			&.mid {
				font-size: vw(48);
			}

			&.high {
				font-size: vw(32);
			}
		}

		.name {
			font-size: vw(18);
			color: var(--color-background-secondary);
			line-height: vw(20);
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: vw(4);

			.de & {
				font-size: vw(14);
			}

			.es & {
				font-size: vw(16);
			}
		}

		.position {
			font-size: vw(12);
			line-height: vw(20);
			text-transform: uppercase;
			font-weight: bold;
			
			display: none;
		}
	}

	&.enter,
	&.appear {
		> .box,
		.stat {
			opacity: 0;
			transform: translate(0, vw(50));
		}

		&-active {
			> .box,
			.stat {
				opacity: 1;
				transform: translate(0, 0);
				transition: opacity 0.6s $easeOutCubic var(--delay, 0s), transform 0.6s $easeOutCubic var(--delay, 0s);
			}
		}

		> .box { --delay: 0s }

		.stats-ctn {
			@for $i from 1 through 20 {
				.stat:nth-child(#{$i}) { --delay: #{0.05 * $i + 0.2}s; }
			}
		}
	}
}